<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Atualizar faq #{{id}}</h3>
        <p style="text-align: left">Segue abaixo o formuário de cadastro. Preencha com atenção.</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar" @click="$router.go(-1)">voltar</button>
    </div>
    <form class="formulario" autocomplete='off' v-if="!loading">
      <v-row class="mb-7">
        <v-col
          cols="12"
          sm="12"
        >
          <v-textarea
            @mousedown="(e) => selecionarcampo(e)"
            v-model="pergunta"
            name="pergunta"
            label="Pergunta"
            :error-messages="erros.pergunta"
            outlined
          ></v-textarea>
        </v-col>
      </v-row> 
      <v-row class="mb-7">
        <v-col
          cols="12"
          sm="12"
        >
          <!-- <v-textarea
            @mousedown="(e) => selecionarcampo(e)"
            v-model="resposta"
            name="resposta"
            label="Resposta"
            :error-messages="erros.resposta"
            outlined
          ></v-textarea> -->
          <Contrato :content="resposta" v-model="resposta"/>
        </v-col>
      </v-row> 
      <v-row>
        <v-col cols="12" sm="6">
            <v-select
                @mousedown="(e) => selecionarcampo(e, 'sistema')"
                :items="sistemas"
                label="Sistemas"
                name="sistema"
                v-model="sistemasSelecionadosStr"
                :error-messages="erros.sistema"
                outlined chips multiple
                @change="() => requisitarCategorias()"
          ></v-select>
        </v-col>
        <v-col
          class="d-flex"
          cols="12"
          sm="6"
        >           
          <v-autocomplete
            @mousedown="(e) => selecionarcampo(e, 'categoria')"
            :items="categorias"
            label="Categoria"
            name="Categoria"
            v-model="categoria"
            :error-messages="erros.categoria"
            outlined
        >  </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="9"
        >
          <v-btn
            elevation="0"
            :ripple="false"
            class="btn white--text"
            data-cy="btn-envio"
            color="#1377F2"
            type="submit"
            :disabled="reqloading"
            :loading="reqloading"
            @click="submeterform">
            atualizar pergunta
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-btn
              elevation="0"
              :ripple="false"
              class="btn"
              outlined
              color=""
              @click="deletarPergunta">
                deletar
            </v-btn>
        </v-col>
      </v-row>    
    </form>
    <form-skeleton v-if="loading"/>
  </v-container>
  
</template>

<script>
import {alertar} from '@/services/notificacao.service.js'
import FaqServico from '@/services/faq.service.js'
import SistemaServico from '@/services/sistema.service.js'
import FormSkeleton from '../Loading/FormSkeleton.vue'
import Contrato from '@/components/Gadget/Contrato.vue'

export default {
  components: {FormSkeleton, Contrato},
  data(){
    return {
      id: null,
      pergunta: null,
      resposta: null,
      sistema: null,
      sistemas: [],
      sistemasdb: [],
      categoria: null,
      categorias: [],
      categoriasdb: [],
      erros: {
        pergunta: '',
        sistema: '',
        categoria: '',
        resposta: '',
      },
      reqloading: false,
      loading: null,
      sistemasSelecionadosStr: []
    }
  },
  computed: {
    idSistemas() {
      let sistemasSelecionados = this.sistemasdb.filter(x=>this.sistemasSelecionadosStr.includes(x.nome));
      let ids = sistemasSelecionados.map(x=>x.id);
      return ids.join(',')
    }
  },
  methods: {
    async submeterform(e){
      e.preventDefault()
      if(this.validacao()){
        this.reqloading = true
        try{
          const dadospergunta = {
            id: this.id,
            idsistema: this.sistemasdb.filter(el => el.nome === this.sistema)[0].id,
            idcategoria: this.categoriasdb.filter(el => el.nome === this.categoria)[0].id,
            pergunta: this.pergunta,
            resposta: this.resposta,
            idsistemas: this.idSistemas
          }
        
          await FaqServico.upinsertPergunta(dadospergunta)
            .then(res => {
              if(res.status === 200){
                this.reqloading = false
                alertar('success', '=)', 'Pergunta atualizada com sucesso!')
              }
            })
            .catch(e => {
              console.log('erro na criacao de pergunta', e)
              this.reqloading = false
              alertar('warning', '=/', 'Falha na atualização da pergunta!')
            })
        }catch(e){
          console.log(e)
        }
      }

    },
    validacao(){
      let objretorno
      let validado = new Array()
      if(!this.pergunta || this.pergunta.trim() === ''){
        validado.push(false)
        this.erros.pergunta = 'Esse campo é obrigatório'
      }
      if(!this.resposta || this.resposta.trim() === ''){
        validado.push(false)
        this.erros.resposta = 'Esse campo é obrigatório'
      }
      if(!this.sistema){
        validado.push(false)
        this.erros.sistema = 'Esse campo é obrigatório'
      }
      if(!this.categoria){
        validado.push(false)
        this.erros.categoria = 'Esse campo é obrigatório'
      }
      validado.includes(false) ? objretorno = false : objretorno = true
      return objretorno
    },
    selecionarcampo(e, type){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = null
      }
      if(type){
        this.erros[type] = null
      }
    },
    async requisitarSistemas(){
      const sistemas = await SistemaServico.buscar().then(res=> res.data)
      this.sistemas = sistemas.map(res => res.nome)
      this.sistemasdb = sistemas
    },
    async requisitarCategorias(){
      let idsistema = this.sistemasdb.filter(el => el.nome === this.sistema)[0].id
      const categorias = await FaqServico.buscarCategorias({idsistema}).then(res=> res.data)
      this.categorias = categorias.map(res => res.nome)
      this.categoriasdb = categorias
    },
    async requisitarFaq(id){
        const faq = await FaqServico.buscarPerguntas({id}).then(res => res.data[0])
        this.pergunta = faq.pergunta
        this.resposta = faq.resposta
        this.sistema = this.sistemasdb.filter(el => el.id === faq.idsistema)[0].nome
        if (faq.idsistemas) {
          let ids = faq.idsistemas.split(',');
          this.sistemasSelecionadosStr = ids.map(x=>this.sistemasdb.find(s=>s.id===parseInt(x)).nome)
        } else {
          this.sistemasSelecionadosStr.push(this.sistema)
        }
        await this.requisitarCategorias()
        this.categoria = this.categoriasdb.filter(el => el.id === faq.idcategoria)[0].nome
    },
    async deletarPergunta(){
      this.reqloading = true
      await FaqServico.deletarPergunta({id: this.id}).then(res => {
        if(res.status === 200){
          alertar('success', '=)', 'Pergunta deletada com sucesso')
          this.reqloading = false

          this.$router.go(-1)
        }else{
          alertar('warning', '=)', 'Falha ao deletar pergunta')
          this.reqloading = false

        }
      })
    }
  },
  async mounted(){
    this.loading = true
    const {id} = this.$route.params
    this.id = id
    await this.requisitarSistemas()
    await this.requisitarFaq(id)
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;
    margin: 0 auto;
  width: 60vw;
  max-width: 100%;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.btn{
  margin-top: 1em;
  width: 100%;
  height: 3em !important;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;
}
.formulario{
  margin: 0 auto 5em auto;
  width: 900px;
  max-width: 80vw;
  box-shadow:  4px 4px 20px #d9d9d9,;
  padding: 5em 1em 3em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

.theme--light.v-messages {
    color: red !important;
}

//css indesejados
.v-input, .v-text-field{
  outline: none !important;
}
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12{
  padding: 0 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset !important;
    padding: unset !important;
  }
</style>