<template>
    <AtualizarFaq />
</template>

<script>
import AtualizarFaq from '@/components/Faq/AtualizarFaq'
export default {
  components: {AtualizarFaq},
  data(){
    return{}
  }
}
</script>

<style>

</style>